export const columns = [{
        title: 'No',
        slots: { customRender: 'no' },
    },
    {
        title: 'Version',
        dataIndex: 'version',
    },
    // {
    //     title: 'Title',
    //     dataIndex: 'title',
    // },
    {
        title: 'Deskripsi',
        // dataIndex: 'deskripsi',
        slots: { customRender: 'deskripsi' },
    },
    {
        title: 'Tanggal Rilis',
        dataIndex: 'date_rilis',
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        slots: { customRender: 'status' },
    },

    {
        title: 'Action',
        slots: { customRender: 'action' },
    },
]