<template>
  <div class="row">
    <div class="col">
      <a-select
        style="width: 100%"
        placeholder="Pilih Status"
        :options="[
          { value: null, label: 'Semua' },
          { value: 1, label: 'Aktif' },
          { value: 0, label: 'Tidak Aktif' },
        ]"
        v-model:value="filters.is_active"
      ></a-select>
    </div>
    <div class="col">
      <a-input placeholder="cari ..." v-model:value="filters.q"></a-input>
    </div>
    <div class="col">
      <a-button type="primary" title="cari" @click="search" :loading="loading">
        <i v-if="!loading" class="fa fa-search" aria-hidden="true"></i>
        <span v-else>Memuat Data ...</span>
      </a-button>
    </div>
  </div>
  <div class="row justify-content-end mb-2">
    <div class="col-lg-6 col-md-12"></div>
    <div class="col-lg-6 col-md-12 text-right">
      <a-button title="Tambah Version Rilis" type="primary" @click="tambah">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </a-button>
    </div>
  </div>
</template>

<script>
import { reactive, inject, ref } from 'vue'

export default {
  name: 'MFilter',
  props: {
    filter: {
      type: Object,
      default: () => {
        active: null
        q: null
      },
    },
    loading: [Boolean],
  },
  emits: ['update:filter'],
  setup(props, { emit }) {
    const source = inject('source')
    const show = inject('show')
    const showState = inject('showState')
    const q = ref('')

    const tambah = () => {
      show.value = true
      showState.title = 'Tambah Version Rilis'
      showState.state = 'new'
      showState.readOnly = false
      showState.record = {}
      showState.record.deskripsi = null
    }
    const filters = reactive({
      active: null,
      active: null,
      q: null,
    })

    const search = () => {
      emit('update:filter', Object.assign({}, filters))
    }
    return { source, show, showState, tambah, q, emit, filters, search }
  },
}
</script>
