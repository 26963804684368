<template>
  <div class="">
    <div class="table-responsive text-nowrap">
      <md-table
        v-if="meta"
        :columns="columns"
        :data-source="source.items"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #deskripsi="{record}">
          <span v-html="record.deskripsi"></span>
        </template>
        <template #action="{record}">
          <span class="d-flex align-items-center">
            <a-tooltip title="Lihat Version Rilis">
              <a-button class="button" size="small" @click="lihatVersionRilis(record)">
                <i class="fe fe-eye" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Ubah Version Rilis">
              <a-button class="button" size="small" @click="ubahVersionRilis(record)">
                <i class="fe fe-edit" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Hapus Version Rilis">
              <a-button class="button" size="small" danger @click="hapusVersionRilis(record)">
                <i class="fe fe-user-minus" />
              </a-button>
            </a-tooltip>
          </span>
        </template>
        <template #status="{ text }">
          <span
            :class="[
              text === true
                ? 'font-size-12 badge badge-primary'
                : 'font-size-12 badge badge-default',
            ]"
            >{{ text ? 'Aktif' : 'Non Aktif' }}</span
          >
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { columns } from './columns'

import { Modal, message } from 'ant-design-vue'

import { inject, ref, computed } from 'vue'
import apiClient from '@/services/axios'

import { useRouter } from 'vue-router'

export default {
  setup(props) {
    const router = useRouter()
    const source = inject('source')
    const show = inject('show')
    const showState = inject('showState')
    const params = inject('params')
    const refresh = inject('refresh')

    const meta = computed(() => source.value._meta)

    const startRow = ref(1)
    const handleTableChange = (pag, filters, sorter) => {
      params.page = pag.current
      params.perPage = pag.pageSize
      refresh()
      startRow.value = (pag.current - 1) * pag.pageSize + 1
    }
    const lihatVersionRilis = record => {
      show.value = true
      showState.title = 'Lihat Version Rilis'
      showState.record = record
      showState.readOnly = true
      showState.state = 'show'
    }

    const ubahVersionRilis = record => {
      show.value = true
      showState.title = 'Ubah Version Rilis'
      showState.record = record
      showState.state = 'edit'
      showState.readOnly = false
      source.items = []
    }

    const hapusVersionRilis = record => {
      Modal.confirm({
        title: 'Konfirmasi Hapus Version Rilis',
        content: 'Apakah anda ingin hapus version ini?',
        onOk() {
          apiClient
            .delete('/api/version-rilis/' + record.id)
            .then(({ data }) => {
              refresh()
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }

    return {
      source,
      columns,
      meta,
      startRow,
      handleTableChange,
      show,
      showState,
      lihatVersionRilis,
      ubahVersionRilis,
      hapusVersionRilis,
      params,
      router,
    }
  },
}
</script>

<style scoped>
.button {
  margin: 0 4px;
}
</style>
